import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { IPv6Badge } from './IPv6Badge'
import moment from 'moment';
import './sticky-footer-navbar.css';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <Container fluid={true}>
                    <span className="text-muted">
                        @ 2007-{moment().year()} Alien Laboratory.&nbsp;<IPv6Badge />
                    </span>
                </Container>
            </footer>
        );
    }
}