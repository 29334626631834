import React, { Component } from "react";
import logo from "../../logo.svg";
import "./About.css";

export default class About extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Alien Laboratory 官方网站</p>
          <small>
            <code>Powered by <a className="App-link"
              href="https://react.dev/"
              target="_blank"
              rel="noopener noreferrer">React</a> & <a className="App-link"
                href="https://getbootstrap.com/"
                target="_blank"
                rel="noopener noreferrer">Bootstrap</a>
            </code>
          </small>
        </header>
      </div>
    );
  }
}
