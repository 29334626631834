import { useState, useEffect } from "react";
import { Badge } from "reactstrap";
import { isIPv6 } from 'is-ip';

export function IPv6Badge() {
    const [ServerIP, setServerIP] = useState('');
    const [IPv6Visible, setIPv6Visible] = useState(false);

    useEffect(() => {
        fetch(`/api/server/GetServerIp`)
            .then(res => res.text())
            .then((result) => {
                if (isIPv6(result)) {
                    setServerIP(result);
                    setIPv6Visible(true);
                }
            }, (error) => {
                console.error(error)
            })
    }, []);

    const IPv6Url = `http://[${ServerIP}]`;
    return IPv6Visible ?
        <Badge color="success"
            title='IPv6'
            href={IPv6Url}
            target="_blank"
            rel="noopener noreferrer">IPv6</Badge> : null;
}
